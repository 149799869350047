input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.fb-page {
  opacity: 0;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
