.account-update-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  row-gap: 20px;
  margin-top: 50px;

  // ion-input {
    // width: 50%;
  // }
  
  .input-email-update {
    width: 50%;
    ion-label {
      padding-left: 0;
      margin-bottom: 8px;
      color: #ffffff;
    }
  }

  .input-password-update {
    width: 50%;
    ion-label {
      padding-left: 0;
      margin-bottom: 8px; 
      color: #ffffff;
    }
  }

  .enter-button {
    margin-top: 30px;
  }

  ion-button {
    --background: transparent linear-gradient(180deg, #AE00B3 0%, #D50087 100%) 0% 0% no-repeat padding-box;
    --border-radius: 12px;
    width: 150px;
  }
}