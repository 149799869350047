@import "../../shared/mixins";

.login-card {
  width: var(--custom-md);
  // height: 70%;
  height: 100%;
  border-radius: 10px;
  color: #ffffff;
  overflow-y: visible;

  .login-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    .header-content {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;

      ion-icon {
        width: 20px;
        height: 20px;
        z-index: 9999;
        cursor: pointer;
      }

      ion-card-title {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-left: -20px;
      }
    }

    .sign-up-button {
      margin-top: -3px;
    }
  }

  .login-title-left {
    // margin: -20px 0 20px 0;
    margin: -20px 20px 20px 20px;
    border-bottom: 1px solid #D4D4D4;
    padding-left: 0;
    line-height: 2;
    // box-shadow: 0 5px 0 -4px #d4d4d4;
    // text-underline-offset: 5px;
    // width: 100%;
  }

  ion-item {
    &::part(native) {
      border-bottom-color: #9f9f9f;
    }

    @include autofill-color(var(--ion-color-secondary-new));
  }


  .login-card-content {
    // padding: 0 20px 0 20px;
    // min-height: 100%;

    .input-username,
    .input-password {
      padding-left: 20px;

      ion-label {
        padding-left: 0px;
        color: #ffffff;
        margin-bottom: 5px;
      }

      ion-input {
        padding-left: 10px !important;
      }
    }
  }

  // .login-subheader {
  //   display: flex;
  //   align-items: center;

  //   ion-button {
  //     margin: 0 4px;
  //   }
  // }

  .rem-forget-col {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .remember-me-col {
      .remember-me {
        ion-label {
          margin-left: 10px;
          color: #ffffff;
        }
      }
    }

    .forgot-password-col {
      ion-label {
        color: #ffffff;
      }
    }
  }

  // .remember-me {
  //   &::part(native) {
  //     min-height: 32px;
  //     padding-inline-start: 12px;
  //   }

  //   ion-checkbox {
  //     margin: 0;
  //   }

  //   ion-label {
  //     margin: 8px 12px;
  //     font-size: 14px;
  //   }
  // }

  // .forgot-password {
  //   &::part(native) {
  //     min-height: auto;
  //   }

  //   ion-label {
  //     font-size: 12px;
  //     text-align: end;
  //   }
  // }

  .login-g-fb-row {
    display: flex;
    flex-direction: column;
    align-items: center;

    .google-login,
    .facebook-login {
      width: 100%;
      max-width: 300px;
      height: 44px;
      margin: 8px 0;
    }

    .google-login {
      --background: #ffffff;

      ion-img {
        margin-inline-end: 16px;
        width: 20px;
      }

      ion-label {
        color: black;
      }
    }

    .facebook-login {
      --background: #1877f2;
      margin: 12px 0;

      ion-icon {
        margin-right: 8px;
        font-size: 24px;
        color: #ffffff;
      }
    }
  }

  .login-with-phone {
    .login-title-right {
      margin: -20px 20px 20px 20px;
      border-bottom: 1px solid #D4D4D4;
      padding-left: 0;
      line-height: 2;
    }

    ion-label {
      margin-left: 20px;
      margin-bottom: 5px;
    }

    .login-with-phone-input {
      margin: 0 0 0 20px;
      display: flex;
      // justify-content: center;
      // align-items: center;
      column-gap: 10px;

      .country-code {
        width: 130px;
        margin-top: 5px;

        .login-country-code {
          --background: #ffffff;
          --color: #000;
          --border: 1px solid #707070;
          --border-radius: 4px;
          width: 100%;

          ion-input {
            padding-bottom: 2px !important;
          }

          ion-icon {
            color: #000;
          }
        }
      }

      .phone-number-input {
        width: 100%;
        // .login-phone-number {
        //   ion-input {

        //   }
        // }
      }

    }

    .receive-otp-radio-buttons {
      
      .radio-buttons {
        // margin-left: 20px;
        // margin-top: 10px;
        margin: 10px 0 20px 20px;
        ion-radio-group {
          display: flex;
          flex-direction: row;
          gap: 40px;
          // align-items: center;
          // width: 200px;
          // margin: 40px 0px 0px 20px;
  
          .radio-button {
            display: flex;
            justify-content: center;
            // align-items: center;
            // gap: 10px;
          }
        }
      }
    }

    .g-recaptcha {
      margin-left: 5px;
    }
  }

  .login-skip-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    column-gap: 20px;

    .login-button-div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // width: 50%;
      margin-left: 20px;

      ion-button {
        --background: transparent linear-gradient(180deg, #AE00B3 0%, #D50087 100%) 0% 0% no-repeat padding-box;
        --border-radius: 12px;
        width: 100px;

        ion-spinner {
          margin-left: 10px;
        }
      }
    }

    .skip-button-div {
      ion-button {
        --background: transparent;
        --border-radius: 12px;
        width: 100px;
        color: #ffffff !important;
      }
    }
  }


  // .login-button {
  //   margin-top: 4px;
  //   margin-right: 5px;
  // }

  // .skip-anonymous {
  //   background-color: #ffffff;
  //   color: #000;
  //   border-radius: 4px;
  // }

  @media (max-width: 992px) {
    width: 100%;

    .login-button {
      width: 100%;
    }

    .skip,
    .skip-anonymous {
      width: 100%;
    }

    .login-g-fb-col {
      align-items: flex-start;

      .google-login,
      .facebook-login {
        max-width: none;
      }
    }
  }
}

.login-card::-webkit-scrollbar {
  width: 4px;
}

.login-card::-webkit-scrollbar-thumb {
  background: rgba(233, 30, 99, 0.5);
  border-radius: 16px;
  padding: 2px;
  opacity: 1;
}