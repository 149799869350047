.stream-side-bar {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: auto;
  padding: 8px 8px 20px;

  &.fullscreen {
    ion-buttons ion-button {
      --background: rgba(107, 107, 107, 0.4);
    }
  }

  ion-buttons {
    flex-direction: column;

    //.invite-button.button-has-icon-only,
    //.fullscreen-button.button-has-icon-only {
    //  margin-top: 20px;
    //}

    .chat-button ion-badge {
      position: absolute;
      top: -8px;
      right: -4px;

      &.show-delay {
        animation: fadein .3s ease 0s normal forwards 1;

        @keyframes fadein {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
  }
}
